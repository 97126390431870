<template>
  <section id="dashboard-analytics">
    <!--botpress /-->
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <welcome-box
          :data="data"
          :is-loading="isLoading_welcome"
        />
      </b-col>
      <b-col
        lg="6"
        md="12"
      >
        <status-layanan
          :data="data"
          :is-loading="isLoading_welcome"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <history-tagihan
          :data="invoice"
          :data-detail="dataDetail"
          :is-loading="isLoading_welcome"
        />
      </b-col>
      <b-col lg="4">
        <history-tiket
          :is-loading="isLoading_welcome"
          :timeline="timeline"
          :day="timeline_day"
          :month="timeline_month"
          :date="timeline_date"
        />
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col cols="12">
        <div class="group-area mt-1">
          <h4>Shortcuts</h4>
          <p>Knowledge Base</p>
          <hr />
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col>
        <knowledge-base></knowledge-base>
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

import Loading from '@core/components/loading/Loading.vue'
import WelcomeBox from './WelcomeBox.vue'
import StatusLayanan from './StatusLayanan.vue'
import HistoryTiket from './HistoryTiket.vue'
import HistoryTagihan from './HistoryTagihan.vue'
import KnowledgeBase from '../knowledge-base/KnowledgeBase.vue'
// import Botpress from "../botpress/Botpress.vue";

export default {
  components: {
    BRow,
    BCol,
    WelcomeBox,
    StatusLayanan,
    HistoryTiket,
    HistoryTagihan,
    ToastificationContent,
    KnowledgeBase,
    Loading,
    // Botpress
  },
  data() {
    return {
      data: {},
      dataDetail: {},
      timeline: [],
      invoice: [],
      timeline_day: null,
      timeline_date: null,
      timeline_month: null,
      isLoading: false,
      isLoading_welcome: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // document.getElementById("bp-web-widget").style.display = "inline";
      this.isLoading = true
      this.isLoading_welcome = true
      axios
        .get('dashboard/data')
        .then(response => {
          this.data = response.data.data
          this.invoice = response.data.invoices
          this.timeline = response.data.ticket
          this.timeline_day = response.data.ticket_day
          this.timeline_date = response.data.ticket_date
          this.timeline_month = response.data.ticket_month
          localStorage.setItem(
            'customer_virtual_account',
            this.data.virtual_account,
          )

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Dashboard',
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dashboard',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dashboard',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.isLoading_welcome = false
        })
      this.getdata()
    },
    getdata() {
      axios
        .get('billing/detail-transaction')
        .then(response => {
          this.dataDetail = response.data.data
          // console.log(this.dataDetail);
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dashboard',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dashboard',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
