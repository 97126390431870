<template>
  <b-card
    v-if="data"
    no-body
    class="card-company-table"
  >
    <div v-if="nowLoading">
      <loading />
    </div>
    <div v-else>
      <b-table
        v-if="!isMobile"
        :items="data"
        responsive
        :fields="fields"
        class="mb-0"
        show-empty
      >
        <template
          #empty
          style="text-align: center"
        >
          <span>You have no Invoice</span>
        </template>
        <template #cell(total_amount_due)="data">
          {{ formatPrice(data.value) }}
        </template>
        <template #cell(payment)="data">
          <template v-if="data.item.status_payment == '2'">
            <b-badge
              pill
              variant="light-success"
            >
              Paid
            </b-badge>
          </template>
          <template v-else>
            <b-badge
              pill
              variant="light-danger"
            >
              Unpaid
            </b-badge>
          </template>
        </template>
        <template #cell(action)="data">
          <!--b-link class="nav-link" @click="download(data.item.invoice_no)">
            <feather-icon icon="FileTextIcon" size="21" />
          </b-link-->
          <b-link
            class="nav-link"
            title="Download Invoice"
            @click="download(data.item.invoice_no)"
          >
            <feather-icon
              icon="FileTextIcon"
              size="21"
            /> Invoice
          </b-link>
          &nbsp;
          <b-link
            v-if="data.item.file_faktur != null"
            class="nav-link"
            title="Download Faktur Pajak"
            @click="download_tax(data.item.file_faktur)"
          >
            <feather-icon
              icon="FileIcon"
              size="21"
            /> Faktur Pajak
          </b-link>
        </template>
      </b-table>

      <div v-if="isMobile">
        <b-card
          v-for="(data, index) of data"
          :key="index"
          no-body
          class="card-company-table"
        >
          <!--template slot="header">
            <h4 class="card-title mb-1">Last Invoice</h4>
          </template-->
          <b-card-body>
            <b-row>
              <b-col md="12">
                <h4
                  class="card-title mb-1"
                  style="float: left; margin-top: 5px"
                >
                  {{ data.bill_period }}
                </h4>
                <span
                  class="card-title mb-1"
                  style="float: right"
                >
                  <b-badge
                    v-if="data.status_payment == '2'"
                    pill
                    variant="light-success"
                  >Paid</b-badge>
                  <b-badge
                    v-else
                    pill
                    variant="light-danger"
                  > Unpaid </b-badge>
                </span>
                <div style="clear: both" />
                <b-row>
                  <div class="col-5">
                    <strong>No Invoice</strong>
                  </div>
                  <div class="col-7">
                    : {{ data.invoice_no }}
                  </div>
                </b-row>
                <b-row>
                  <div class="col-5">
                    <strong>Total</strong>
                  </div>
                  <div class="col-7">
                    : {{ formatPrice(data.total_amount_due) }}
                  </div>
                </b-row>
                <b-row>
                  <div class="col-5">
                    <strong>Due Date</strong>
                  </div>
                  <div class="col-7">
                    : {{ data.due_date }}
                  </div>
                </b-row>
                <!--div> No : {{data.ticket_number}} </div>
                  <div> category: {{data.name}} </div-->
                <br>
                <span style="float: left" />
                <span
                  style="float: right"
                  @click="download(data.invoice_no)"
                ><feather-icon
                  size="20"
                  :icon="'DownloadIcon'"
                /></span>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>

      <!-- table detail -->
      <!--<table id="customers">
        <thead>
          <tr>
            <th>Name</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="detail of dataDetail">
              <tr>
                <td>{{detail.name}}</td>
                <td>{{detail.total}}</td>
              </tr>
              <template v-if="detail['child']" v-for="child of detail['child']">
                <tr style="margin-left:20px;">
                  <td>&raquo; {{child['name']}}</td>
                  <td>{{ formatPrice(child['total'])}}</td>
                </tr>
              </template>
          </template>
        </tbody>
      </table>-->
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BRow,
  BCol,
  BCardBody,
} from 'bootstrap-vue'
import Loading from '@core/components/loading/Loading.vue'
import axios from 'axios'
import axios2 from '@axios'
import { isMobile } from 'mobile-device-detect'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    Loading,
    BLink,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    dataDetail: {
      type: [Array, Object],
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isMobile,
      loading: null,
      fields: [
        { key: 'bill_period', label: 'BILL PERIOD' },
        { key: 'due_date', label: 'DUE DATE' },
        { key: 'invoice_no', label: 'NO INVOICE' },
        { key: 'total_amount_due', label: 'TOTAL' },
        { key: 'payment', label: 'PAYMENT' },
        { key: 'action', label: 'VIEW' },
      ],
      fieldsDetail: [
        { key: 'name', label: 'Nama' },
        { key: 'total', label: 'Total' },
      ],
    }
  },
  computed: {
    nowLoading() {
      return this.loading === null ? this.isLoading : this.loading
    },
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    download(invoice) {
      const clean_invoice = invoice.split('/')
      this.loading = true

      // --TESTING DOANG. SILAKAN DI HAPUS--
      // clean_invoice[1] = '20210032058';
      // --------------------------
      axios({
        url:
          `https://api-portal.netciti.co.id/api-invoice/pdf.php?invoice=${
            clean_invoice[1]}`,
        method: 'GET',
        // headers: { "Access-Control-Allow-Origin": "*" },
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${invoice}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false
        })
    },
    download_tax(file_faktur) {
      this.loading = true
      axios2({
        url: `export/faktur/${file_faktur}`,
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file_faktur)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

//----------------------------------- CSS COPAS W3SCHOOL ----------------------------------
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 97%;
  margin: 10px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #887ef2;
  color: white;
}
//----------------------------------- END CSS COPAS W3SCHOOL -------------------------//
</style>
