<template>
  <b-card v-if="timeline" no-body class="card-developer-meetup">
    <template v-if="isLoading">
      <loading></loading>
    </template>
    <template v-else>
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/illustration/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">{{ day }}</h6>
            <h3 class="mb-0">{{ date }}</h3>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25"> {{ $t("Ticket History") }} </b-card-title>
            <b-card-text class="mb-0"> {{ month }} </b-card-text>
          </div>
        </div>

        <app-timeline>
          <app-timeline-item v-for="t in timeline" :key="t.id" variant="primary">
            <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
              <h6>{{ t.ticket_number }}</h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{t.finish_time !== null ? dateTimeFormat(t.finish_time) : ""}}</small>
            </div>
            <!--b-media style="margin-bottom:5px;">
              <small class="align-top" v-if="t.assigned_to_name != null" style="margin-left:3px;margin-bottom:5px;"><feather-icon size="13" :icon="'UserIcon'" /> {{t.assigned_to_name}}</small>
            </b-media-->


            <b-media style="margin-bottom:10px;" v-if="t.last_log_by_system != null">
              <template #aside>
                <feather-icon size="24" :icon="'SettingsIcon'" />
              </template>
              <div v-html="t.last_log_by_system"></div>
            </b-media>

            <b-badge pill variant="light-success" v-if="t.finish_time !== null">Finish</b-badge>
            <b-badge pill variant="light-danger" v-else>On Progress</b-badge>
          </app-timeline-item>
        </app-timeline>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import * as moment from "moment";
import Loading from "@core/components/loading/Loading.vue";

import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
    BBadge,
    Loading,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    day: {
      type: String,
      default: () => "",
    },
    date: {
      type: String,
      default: () => "",
    },
    month: {
      type: String,
      default: () => "",
    },
    timeline: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    dateTimeFormat: function (date) {
      return moment(date).format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>
