<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations bg-card-congratulations"
  >
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <h1 class="mb-1 mt-100">&nbsp;</h1>
    <template v-if="isLoading">
      <loading></loading>
    </template>
    <template v-else>
      <b-card-text class="m-auto card-text" style="padding: 50px">
        Status Layanan<br />
        <h3 class="text-white">Layanan Siap digunakan</h3>
      </b-card-text>
    </template>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";
import Loading from "@core/components/loading/Loading.vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    Loading
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
.bg-card-congratulations {
  background: linear-gradient(118deg, #dddd, rgba(115, 103, 240, 0.7));
}
</style>
