<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations"
  >
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <template v-if="isLoading">
      <loading />
    </template>
    <template v-else>
      <h1 class="text-white text-left">
        {{ data.alias_name }}
      </h1>
      <b-card-text class="w-100 text-left">
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-white">
                  {{ data.customer_id }}
                </h4>
                <b-card-text class="font-small-3 mb-0 text-white">
                  {{ data.virtual_account }}
                </b-card-text>
                <b-card-text
                  v-if="data.address && data.address != ''"
                  class="font-small-3 mb-0 text-white"
                >
                  {{ data.address }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <!-- <p>ID: <strong>{{ data.customer_id }}</strong><br />{{ data.virtual_account }}</p> -->
      </b-card-text>
      <b-card-text
        v-if="data.package_name && data.package_name != ''"
        class="w-100"
      >
        <blockquote
          class="blockquote pr-1 mt-2 text-right border-right-primary border-right-3"
        >
          Your Package
          <h4 class="text-white">
            {{ data.package_name }}
          </h4>
        </blockquote>
      </b-card-text>
    </template>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BMedia,
  BMediaBody,
} from 'bootstrap-vue'
import Loading from '@core/components/loading/Loading.vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    Loading,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style>
.border-right-primary {
  border-right: 1px solid #fff;
}
</style>
